import firebase from "firebase/compat/app";
import "firebase/compat/database"; 
import 'firebase/compat/storage'; // Add this line
import "firebase/compat/auth";

const firebaseConfig = {

  // apiKey: "AIzaSyCPrKFCOzUacvgMf0roQBmopVyPJwze_B4",
  // authDomain: "crud-758b3.firebaseapp.com",
  // databaseURL: "https://crud-758b3-default-rtdb.firebaseio.com",
  // projectId: "crud-758b3",
  // storageBucket: "crud-758b3.appspot.com",
  // messagingSenderId: "845011600231",
  // appId: "1:845011600231:web:1cb166339d378bcb4325c2",
  // measurementId: "G-T6FERT3VEP"
  apiKey: "AIzaSyA0Eu2wjlr7xjR91M1EYo5TTVsN_cKD2Gw",
  authDomain: "covenant-b40b9.firebaseapp.com",
  databaseURL: "https://covenant-b40b9-default-rtdb.firebaseio.com",
  projectId: "covenant-b40b9",
  storageBucket: "covenant-b40b9.appspot.com",
  messagingSenderId: "248425842959",
  appId: "1:248425842959:web:989d7eb737c6b7f075967a",
  measurementId: "G-Q1FPY89T9Q"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth(); // Initialize Firebase Authentication
const storage = firebase.storage();

export { firebase, auth, storage };